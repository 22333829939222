import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ShopContextProvider, ShopContext } from "./context/ShopContext";
import { HelmetProvider } from "react-helmet-async";

const root = document.getElementById("root");

const render = () => {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <ShopContextProvider>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ShopContextProvider>
    </React.StrictMode>
  );
};

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
