import React from "react";
import { motion } from "framer-motion";
import styles from "./page.module.scss";
import BackgroundImage from "../../assets/background.avif";
import Logo from "../../assets/logo/cork-square-logo.png";
import Carousel1 from "../../assets/carousel/DSC02687-Edit.avif";
import Carousel2 from "../../assets/carousel/DSC02939-Edit.avif";
import Carousel3 from "../../assets/carousel/DSC02972.avif";
import Carousel4 from "../../assets/carousel/DSC03083-Edit.avif";
import SEO from "../../context/SEO";

export default function Error() {
  const bgColor = "#f5f5f5";

  return (
    <div
      className={styles.container}
      style={{ fontFamily: "Benzin Bold", backgroundColor: bgColor }}
    >
      <SEO title="Cork Matters" />
      <div className={styles.body}>
        <div className={styles.left}>
          <div className={styles.paragraphs}>
            <div className={styles.announcement} style={{ color: "#F15A24" }}>
              Estamos a construir o novo web site para si!...em breve estaremos
              on line.{" "}
            </div>
            <div className={styles.announcement}>
              We’re building a new website for you!...we’ll be online soon.{" "}
            </div>
            <div className={styles.email}>apeixoto@simpleformsdesign.com</div>
            <div className={styles.address}>
              <span>Rua do Rosário 143 - 145</span>
              <span>4050-519</span>
              <span>Porto</span>
              <span>Portugal</span>
              <span>T +351 91 966 1000</span>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <img src={Carousel1} alt="Cork Matters Background" />
        </div>
      </div>
    </div>
  );
}
