import { createContext, useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../Firebase";

export const ShopContext = createContext(null);

export const ShopContextProvider = (props) => {
  const [products, setProducts] = useState([
    {
      name: "",
      id: "",
      collection: "",
      family: "",
      materials: [],
      shortDescription: "",
      description: "",
      dimensions: { height: undefined, width: undefined, length: undefined },
      mass: undefined,
      size: "",
      variations: [
        {
          colour: "",
          stripeId: "",
          price: undefined,
          quantity: {
            inShop: 0,
            allocated: 0,
            inStock: 0,
            onOrder: 0,
          },
          imageSrc: [],
        },
      ],
    },
  ]);
  const [cartItems, setCartItems] = useState({});
  const [isCartOverlayVisible, setIsCartOverlayVisible] = useState(false);
  const [lastAddedItem, setLastAddedItem] = useState(null);

  const fetchProducts = async () => {
    const querySnapshot = await getDocs(collection(db, "products"));
    const newData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setProducts(newData);
  };

  // Use useEffect to call fetchProducts when the component mounts
  useEffect(() => {
    fetchProducts();
  }, []); // The empty array ensures this only runs once when the component mounts

  const toggleCartOverlay = () => {
    setIsCartOverlayVisible(!isCartOverlayVisible);
  };

  const getTotalCartAmount = () => {
    let totalAmount = 0;
  
    // Iterate over each product in the cart
    for (const itemId in cartItems) {
      // Find the corresponding product in the products data
      const product = products.find((product) => product.id === itemId);
  
      if (product) {
        // Iterate over each variation in the cart item
        for (const variationIndex in cartItems[itemId]) {
          const quantity = cartItems[itemId][variationIndex].quantity;
          const variation = product.variations[variationIndex];
  
          // Ensure the variation exists and has a price
          if (variation && variation.price) {
            totalAmount += quantity * variation.price;
          }
        }
      }
    }
  
    return totalAmount;
  };

  const getTotalCartItemCount = () => {
    let itemCount = 0;
    for (const item in cartItems) {
      itemCount += cartItems[item];
    }
    return itemCount;
  };

  const addToCart = (product, variationIndex, quantityToAdd) => {
    const itemId = product.id;
    const variationId = variationIndex;

    setLastAddedItem({
      itemId,
      variationIndex,
      quantityToAdd,
      priceId: variationId,
    });

    setCartItems((prev) => {
      const itemExists = prev[itemId];
      const variationExists = itemExists
        ? itemExists[variationId] != null
        : false;

      // If the item and variation exist, update the quantity
      if (variationExists) {
        return {
          ...prev,
          [itemId]: {
            ...prev[itemId],
            [variationId]: {
              ...prev[itemId][variationId],
              quantity: prev[itemId][variationId].quantity + quantityToAdd,
            },
          },
        };
      } else {
        // If the item exists but not the variation, add the new variation
        if (itemExists) {
          return {
            ...prev,
            [itemId]: {
              ...prev[itemId],
              [variationId]: {
                quantity: quantityToAdd,
              },
            },
          };
        } else {
          // If the item does not exist, add the item and the variation
          return {
            ...prev,
            [itemId]: {
              [variationId]: {
                quantity: quantityToAdd,
              },
            },
          };
        }
      }
    });
  };

  const removeFromCart = (itemId, variationIndex) => {
    setCartItems((prev) => {
      const updatedCart = { ...prev };
      if (updatedCart[itemId] && updatedCart[itemId][variationIndex]) {
        if (updatedCart[itemId][variationIndex].quantity > 1) {
          updatedCart[itemId][variationIndex].quantity -= 1;
        } else {
          // If the quantity is 1, remove the variation entirely
          delete updatedCart[itemId][variationIndex];
          
          // If there are no more variations left for the product, remove the product as well
          if (Object.keys(updatedCart[itemId]).length === 0) {
            delete updatedCart[itemId];
          }
        }
      }
      return updatedCart;
    });
  };
  
  const updateCartItemCount = (itemId, variationIndex, newAmount) => {
    setCartItems((prev) => {
      const updatedCart = { ...prev };
      if (newAmount > 0) {
        // If the new amount is greater than 0, update the quantity
        if (updatedCart[itemId] && updatedCart[itemId][variationIndex]) {
          updatedCart[itemId][variationIndex].quantity = newAmount;
        } else {
          // If the product or variation does not exist, add it
          if (!updatedCart[itemId]) {
            updatedCart[itemId] = {};
          }
          updatedCart[itemId][variationIndex] = { quantity: newAmount };
        }
      } else {
        // If the new amount is 0 or less, remove the variation
        if (updatedCart[itemId] && updatedCart[itemId][variationIndex]) {
          delete updatedCart[itemId][variationIndex];
          
          // If there are no more variations left for the product, remove the product as well
          if (Object.keys(updatedCart[itemId]).length === 0) {
            delete updatedCart[itemId];
          }
        }
      }
      return updatedCart;
    });
  };
  

  const checkout = () => {
    setCartItems({});
  };

  const contextValue = {
    cartItems,
    addToCart,
    updateCartItemCount,
    removeFromCart,
    getTotalCartAmount,
    getTotalCartItemCount,
    checkout,
    isCartOverlayVisible,
    toggleCartOverlay,
    lastAddedItem,
    products,
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};
