// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStripePayments } from "@invertase/firestore-stripe-payments";
import { getProducts } from "@invertase/firestore-stripe-payments";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAk3Qzc4kmfiWoUtiIV-xQUyk9bdncLNxo",
  authDomain: "cork-matters-d293d.firebaseapp.com",
  projectId: "cork-matters-d293d",
  storageBucket: "cork-matters-d293d.appspot.com",
  messagingSenderId: "898850031142",
  appId: "1:898850031142:web:43d91fed5f2020dbffc8e7",
  measurementId: "G-GXY5K8WWQ3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const payments = getStripePayments(app, {
  customersCollection: "customers",
});
export const auth = getAuth(app);
export const storage = getStorage(app);

// const products = await getProducts(payments, {
//   includePrices: true,
//   activeOnly: true,
// });
