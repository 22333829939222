import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { analytics } from "../Firebase";
import { logEvent } from "firebase/analytics";

export default function SEO({ title, description, name, type }) {
  useEffect(() => {
    console.log("SEO event logged:", title);
    logEvent(analytics, "user_engagement", {
      title: title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: title,
      platform: "web", // Specify the platform here
    });
    console.log("SEO event logged:", title, description, name, type);
  }, [title, description, name, type]);

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Open Graph / Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {/* Twitter tags */}
      <meta name="twitter:card" content={type} />
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
}
