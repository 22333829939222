import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "@stripe/stripe-js";

import Home from "./pages/Home";
import { ShopContextProvider, ShopContext } from "./context/ShopContext";
import SubscriptionPrompt from "./components/SubscriptionPrompt";
import PageTracker from "./context/PageTracker";
import "./App.css";
import Error from "./pages/Error";

function App() {
  const { isCartOverlayVisible, toggleCartOverlay } = useContext(ShopContext);
  return (
    <div>
      {/* <SubscriptionPrompt /> */}
      <Router>
        <PageTracker />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path="/error" element={<Error />} />
          <Route path="/cancel" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
