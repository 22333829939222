import React, { useRef, useEffect, useState } from "react";
import styles from "./page.module.scss";
import Logo from "../../assets/logo/cork-square-logo.png";
import SEO from "../../context/SEO";
import gsap from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";

import BGVideo from "../../assets/videos/newShopBG85.mp4";

export default function Home() {
  const sentenceRef = useRef(null);
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const sentences = [
    "Estamos a construir o novo website para si! Em breve estaremos on line, com a nossa nova loja para usufruir da compra dos nossos produtos.",
    "We're building a new website for you! We'll be online soon with our new shop so you can enjoy buying your favourite products.",
  ];

  gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);

  // Effect to animate in the new sentence
  useEffect(() => {
    if (sentenceRef.current) {
      // Split the sentence into words using SplitText
      const splitSentence = new SplitText(sentenceRef.current, {
        type: "words",
      });

      // Animate the split words in
      gsap.from(splitSentence.words, {
        opacity: 0,
        y: 60,
        stagger: 0.15,
        duration: 1.5,
        ease: "circ.out",
      });

      // Clean up SplitText instance before a new one is created
      return () => {
        splitSentence.revert(); // Clean up previous SplitText instance
        gsap.killTweensOf(splitSentence.words); // Make sure any animations are killed before unmounting
      };
    }
  }, [sentenceIndex]); // Re-run this effect whenever the sentenceIndex changes

  // Effect to handle sentence fading out and changing
  useEffect(() => {
    const interval = setInterval(() => {
      if (sentenceRef.current) {
        // Animate out the current text
        gsap.to(sentenceRef.current, {
          opacity: 0,
          y: -20,
          duration: 1,
          ease: "power1.in",
          onComplete: () => {
            // Update the sentence index after fading out
            setSentenceIndex((prevIndex) =>
              prevIndex + 1 === sentences.length ? 0 : prevIndex + 1
            );
          },
        });
      }
    }, 10000); // Change inspiration every 10 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [sentenceIndex]); // Dependency array ensures effect is reset when the sentenceIndex changes

  return (
    <div className={styles.container} style={{ fontFamily: "Benzin Bold" }}>
      <div className={styles.logo}>
        <img src={Logo} alt="Cork Matters Logo" />
      </div>
      <SEO title="Cork Matters" />
      <div className={styles.video}>
        <video src={BGVideo} autoPlay loop muted playsInline />
      </div>
      <div className={styles.body}>
        {/* Use a unique key for the announcement to force re-render */}
        <div
          className={styles.announcement}
          ref={sentenceRef}
          key={sentenceIndex}
        >
          {sentences[sentenceIndex]}
        </div>
        <div className={styles.email}>apeixoto@simpleformsdesign.com</div>
        <div className={styles.address}>
          <span>Rua do Rosário 143 - 145</span>
          <span>4050-519</span>
          <span>Porto</span>
          <span className={styles.mobilePortugal}>
            <span>Portugal</span>
            <span className={styles.phoneNumberMobile}>T +351 91 966 1000</span>
          </span>
          <span className={styles.phoneNumber}>T +351 91 966 1000</span>
        </div>
      </div>
    </div>
  );
}
